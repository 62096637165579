import service from '@/api/service'
import Resource from '@/api/Resource'

const users = new Resource('/api/users', 'userModule')

const ENDPOINT_UPDATE_PASSWORD = '/api/update-password/'

export default {
  namespaced: true,

  state: {
    /**
     * Contiene la información del usuario actualmente autenticado.
     *
     * Inicialmente está declarado como nulo para obtener un valor booleano
     * se utiliza para saber si existe o no un usuario actual.
     */
    authUser: null,

    /**
     * Almacena todos los usuarios del sistema.
     */
    users: null,

    /**
     * Término ingresado para buscar un usuario.
     */
    query: null,

    /**
     * Cuando se obtiene la información de un usuario a actualizar,
     * se almacena en esta variable, se declara como nulo inicialmente
     * para saber si actualmente se está editando o no un usuario.
     */
    editingUser: null,

    /**
     * Datos del usuario a cambiar, para la funcionalidad de "ver como"
     */
    viewAsUser: null,

    /**
     * Los datos del usuario actual son guardados de forma temporal.
     */
    cloneAuthUser: null,

    isCretingResource: false,

    isGettingResource: false,

    isDeletingResource: false
  },

  getters: {
    /**
     * Acceso para obtener el usuario si existe, si no,
     * retorna un objeto vacío para no afectar con un valor nulo.
     */
    getAuthUser: ({ authUser }) => {
      if (authUser) {
        return {
          ...authUser,

          email: authUser.email,
          email_verified_at: authUser.email_verified_at,
          roles_checklist: authUser.roles_checklist,
          permissions: authUser.permissions,
          is_deleted: authUser.is_deleted,
          //
          // Empleado
          profesion: authUser.empleado.profesion,
          fecha_ingreso: authUser.empleado.fecha_ingreso,
          estatus: authUser.empleado.estatus,
          unidades_administrativas: authUser.empleado.unidades_administrativas,
          unidades_administrativas_nombres: authUser.empleado.unidades_administrativas_nombres,
          //
          // persona
          nombre: authUser.persona.nombre,
          primer_apellido: authUser.persona.primer_apellido,
          segundo_apellido: authUser.persona.segundo_apellido,
          fecha_creacion: authUser.fecha_creacion,
          ultima_modificacion: authUser.ultima_modificacion,
          ultimo_acceso: authUser.ultimo_acceso
        }
      }

      return {}
    },

    /**
     * Retorna unicamente los nombres de los roles que el usuario autenticado dispone.
     */
    getAuthRoles: (state, getters) => {
      if (getters.getAuthUser) {
        if (!getters.getAuthUser) return []

        if (!getters.getAuthUser.roles_checklist) return []

        return getters.getAuthUser.roles_checklist.map(r => r.name)
      }

      return []
    },

    /**
     * 'getAuthPermissions'.
     *
     * Retorna la lista de permisos del usuario actualmente autenticado.
     *
     * NOTA: Aplica también para la función 'ver como', cuando se está
     * utilizando esta función, retorna los permisos del usuario que se
     * haya seleccionado para "ver la aplicación como el usuario seleccionado".
     */
    getAuthPermissions: (state, getters) => {
      if (getters.isViewingAsAnotherUser) {
        if (!state.viewAsUser) return []

        if (!state.viewAsUser.permissions) return []

        return state.viewAsUser.permissions.map(p => p.name)
      }

      if (getters.getAuthUser) {
        if (!getters.getAuthUser) return []

        if (!getters.getAuthUser.permissions) return []

        return getters.getAuthUser.permissions.map(p => p.name)
      }

      return []
    },

    /**
     * 'isAdmin'.
     *
     * Función que retorna si el usuario actual posee
     * el rol de 'admin'.
     *
     * Nota: Aplica también cuando se está usando la función
     * de 'ver como', ya que retorna únicamente los roles
     * del usuario actualmente autenticado.
     *
     * IMPORTANTE!!!: Este rol siempre debe de tener el nombre de 'admin'.
     */
    isAdmin: (state, getters) => {
      if (getters.getAuthRoles.length <= 0) return false

      return getters.getAuthRoles.includes('admin')
    },

    /**
     * 'isThereUsers'.
     *
     * Retorna para indicar si hay usuarios en el state del módulo.
     */
    isThereUsers: ({ users }) => Boolean(users && users.data),

    /**
     * 'getUsers'.
     *
     * Retorna únicamente la lista de los usuarios obtenidos del backend.
     */
    getUserList: ({ users }, getters) => (getters.isThereUsers ? users.data : []),

    getUsersResource: ({ users }, getters) => (getters.isThereUsers ? users : {}),

    /**
     * 'isEditingUser'.
     *
     * Indica si actualmente se está o no editando un usuario.
     */
    isEditingUser: state => Boolean(state.editingUser),

    /**
     * 'getRolesOfEditingUser'.
     *
     * Retorna una lista de los permisos del usuario que
     * actualmente se está editando, retorna únicamente los nombres.
     */
    getRolesOfEditingUser: state => {
      if (!state.editingUser) return []

      return state.editingUser.roles_checklist.map(r => r.name)
    },

    /**
     * 'isViewingAsAnotherUser'.
     *
     * Función para indicar si se está usando la función de 'ver como',
     * validando si hay datos en el state de 'cloneAuthUser' ó 'viewAsUser'.
     *
     * Nota: El state de 'cloneAuthUser' se utiliza para guardar de forma temporal
     * los datos del usuario actualmente autenticado.
     */
    isViewingAsAnotherUser: state => Boolean(state.cloneAuthUser || state.viewAsUser),

    /**
     * 'isAuthUserOrCloneUser'.
     *
     * Función que recibe como parámetro un 'id' y valida si el id es igual
     * al usuario actualmente autenticado ó al usuario actualmente 'clonado',
     * el cual se utiliza para guardar los datos del usuario autenticado
     * de forma temporal cuando se utiliza la función de 'ver como'.
     *
     * @param {int} id Id del usuario a validar contra el usuario autenticado o el usuario clonado.
     * @returns {Boolean} Retorna falso o verdadero en base a la validación correspondiente.
     */
    isAuthUserOrCloneUser: state => id =>
      Boolean(state.authUser.id === id) ||
      Boolean(state.cloneAuthUser && state.cloneAuthUser.id === id),

    /**
     * 'getAvailableList'.
     *
     * Indica si la lista de usuarios está disponible o no,
     * usado por ejempolo cuando se está haciendo una petición remota,
     * o cuando se está eliminando un elemento e indicar al usuario que
     * se está realizando actualmente una acción.
     *
     * @returns {Boolean} Al ejecutar acciones como 'crear', 'editar' o 'eliminar'.
     */
    getAvailableList: state => {
      return !state.isCretingResource && !state.isGettingResource && !state.setIsDeletingResource
    },

    /**
     * 'isSearchingMode'.
     *
     * Indica si hay un texto de búsqueda para filtrado de usuarios.
     */
    isSearchingMode: state => Boolean(state.query)
  },

  mutations: {
    /**
     * 'resetState'.
     *
     * Elimina todas las variables del state, las formatea a nulo.
     */
    resetState (state) {
      for (const key in state) {
        state[key] = null
      }
    },

    setAuthUser (state, authUser) {
      state.authUser = authUser
    },

    setUsers (state, users) {
      state.users = users
    },

    setUserToEdit (state, user) {
      state.editingUser = user
    },

    /**
     * 'set_viewAsUser_object'.
     *
     * Establece los datos del usuario del cual se quiere inspeccionar
     * o ver la aplicación como ése usuario.
     *
     * @param {Object} user Usuario a cambiar al sesión de forma temporal.
     */
    set_viewAsUser_object (state, user) {
      state.viewAsUser = user
    },

    /**
     * 'set_viewAsUser_mode'.
     *
     * Cambia el modo en que se mira la aplicación al utilizar la
     * función de 'ver como', hace un swap entre el 'cloneAuthUser'
     * y el usuario actualmente autenticado.
     */
    set_viewAsUser_mode (state) {
      if (!state.viewAsUser) return

      state.cloneAuthUser = state.authUser
      state.authUser = state.viewAsUser
    },

    /**
     * 'resetViewAsUserMode'.
     *
     * Resetea el modo normal de la aplicación, cuando se sale
     * de la función de 'ver como'.
     */
    resetViewAsUserMode (state) {
      if (!state.viewAsUser) return

      state.authUser = state.cloneAuthUser
      state.cloneAuthUser = state.viewAsUser = null
    },

    setIsGettingResource (state, payload) {
      state.isGettingResource = payload
    },

    setIsCreatingResource (state, payload) {
      state.isCretingResource = payload
    },

    setIsDeletingResource (state, payload) {
      state.isDeletingResource = payload
    },

    setFilteredUsers (state, payload) {
      state.filteredUsers = payload
    },

    setQuery (state, payload) {
      state.query = payload
    }
  },

  actions: {
    /**
     * Hace una petición al servidor para obtener todos usuarios.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async getUsers ({ state, getters, commit, dispatch }, page = null) {
      if (getters.isSearchingMode) return dispatch('search', { search: state.query, page })

      const { error, message, data } =
          page !== null
            ? await users.get(`api/users?page=${page}`, null, 'setIsGettingResource')
            : await users.all({}, 'setIsGettingResource')

      if (error) return { error, message }

      commit('setUsers', data.data)

      return data
    },

    async search ({ commit }, { search, page }) {
      const { error, message, data } = await users.get(
        'api/users/search',
        { search, page },
        'setIsGettingResource'
      )

      if (error) return { error, message }

      commit('setUsers', data.data)

      return data
    },

    /**
     * Petición asíncrona para crear un nuevo usuario.
     *
     * @param {string} payload.email Email del usuario
     * @param {string} payload.password Contraseña del usuario
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async createUser ({ dispatch }, payload) {
      let retval = {}

      try {
        const { data } = await users.create(payload)

        retval = data

        dispatch('getUsers')
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * Método asíncrono para obtener la información del usuario autenticado.
     *
     * Si se obtiene información del usuario correctamente guarda la información
     * en Vuex, si ha ocurrido un error o se ha obtenido un código de 'no autenticación'
     * entonces borra la información existente en Vuex.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async getAuthUser ({ state, commit, dispatch }) {
      if (state.cloneAuthUser || state.viewAsUser) {
        dispatch('getViewAsUser', state.viewAsUser.id)
        commit('set_viewAsUser_mode')
        return
      }

      try {
        const response = await users.get('/api/user')
        const data = response.data.data

        if (data.error || data.code === 401) return data

        commit('setAuthUser', data)
      } catch (error) {
        commit('setAuthUser', null)
      }
    },

    /**
     * Obtiene la información de un usuario en específico.
     *
     * Se obtiene un usuario basado en su id y la información
     * se guarda en 'setUserToEdit'.
     *
     * @param {int} id Id del usuario a obtener.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async getUserToEdit ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await users.show(id)

        retval = data

        commit('setUserToEdit', { ...data.data })
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * Actualiza la información de un usuario.
     *
     * @param {int} id Id del usuario a actualizar.
     * @param {string} payload.nombre Nombre de la persona.
     * @param {string} payload.email Email del usuario.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async updateUser ({ commit, dispatch }, { id, payload }) {
      let retval = {}

      try {
        const { data } = await users.update(id, payload)

        retval = data

        commit('setUserToEdit', null)

        dispatch('getUsers')
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async updatePassword ({ context }, { id, payload }) {
      let retval = {}

      try {
        const { data } = await service.post(ENDPOINT_UPDATE_PASSWORD + id, payload)

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * Elimina la información de un usuario.
     *
     * @param {int} id Id del usuario a eliminar.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async deleteUser ({ dispatch }, id) {
      let retval = {}

      try {
        const { data } = await users.delete(id, 'setIsDeletingResource')

        retval = data

        dispatch('getUsers')
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async getViewAsUser ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await users.show(id, {
          viewAsUser: true
        })
        retval = data

        commit('set_viewAsUser_object', data.data)
        commit('set_viewAsUser_mode')
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    }
  }
}
