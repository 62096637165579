<template>
  <nav
    class="
          topnav
          navbar
          navbar-expand
          shadow
          justify-content-between
          justify-content-sm-start
          navbar-light
          bg-white"
    id="sidenavAccordion"
  >
    <!-- Navbar Brand-->
    <!-- * * Tip * * You can use text or an image for your navbar brand.-->
    <!-- * * * * * * When using an image, we recommend the SVG format.-->
    <!-- * * * * * * Dimensions: Maximum height: 32px, maximum width: 240px-->
    <router-link :to="{ name: 'Dashboard' }" class="navbar-brand">
      Instituto Campechano
    </router-link>

    <!-- Sidenav Toggle Button-->
    <button
      class="btn btn-icon btn-transparent-dark order-1 order-lg-0 mr-lg-2"
      @click="toggleSidebar"
    >
      <i data-feather="menu"></i>
    </button>

    <!-- Navbar Items-->
    <ul class="navbar-nav align-items-center ml-auto">
      <!-- Alertas -->
      <NotificationsDropDown />

      <!-- Messages Dropdown-->
      <!-- <messages-list></messages-list> -->

      <check-authorization :requiresRoles="['admin']" no-slots :show-alert="false">
        <li class="nav-item dropdown no-caret d-none d-sm-block mr-3 dropdown-notifications">
          <router-link
            :to="{ name: 'PanelDeControl' }"
            class="btn btn-icon btn-transparent-dark dropdown-toggle"
          >
            <i data-feather="settings"></i>
          </router-link>
        </li>
      </check-authorization>

      <!-- User Dropdown-->
      <li class="nav-item dropdown no-caret mr-3 mr-lg-0 dropdown-user">
        <a
          class="btn btn-icon btn-transparent-dark dropdown-toggle"
          id="navbarDropdownUserImage"
          href="javascript:void(0);"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img class="img-fluid" src="@/assets/img/illustrations/profiles/profile-1.png" />
        </a>
        <div
          class="dropdown-menu dropdown-menu-right border-0 shadow animated--fade-in-up"
          aria-labelledby="navbarDropdownUserImage"
        >
          <h6 class="dropdown-header d-flex align-items-center">
            <img
              class="dropdown-user-img"
              src="@/assets/img/illustrations/profiles/profile-1.png"
            />
            <div class="dropdown-user-details">
              <div class="dropdown-user-details-name">
                {{ authUser.nombre }}
              </div>
              <div class="dropdown-user-details-email">
                {{ authUser.email }}
              </div>
            </div>
          </h6>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item my-cursor" @click="getAuthUser">
            <div class="dropdown-item-icon">
              <i data-feather="download"></i>
            </div>
            Actualizar perfil
          </a>
          <div class="dropdown-divider"></div>
          <router-link :to="{ name: 'Profile' }" class="dropdown-item my-cursor">
            <div class="dropdown-item-icon">
              <i data-feather="settings"></i>
            </div>
            Perfil
          </router-link>
          <a class="dropdown-item my-cursor" @click="closeSession">
            <div class="dropdown-item-icon">
              <i data-feather="log-out"></i>
            </div>
            Cerrar sesión
          </a>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
import { EventBus } from '@/EventBus'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import NotificationsDropDown from '@/components/Shared/NotificationsDropDown.vue'
// import MessagesList from '@/components/Shared/MessagesList.vue'

/**
 * Navbar del dashboard principal. Contiene el botón del usuario para cerrar sesión.
 *
 * @example [none]
 */
export default {
  name: 'Navbar',

  components: {
    NotificationsDropDown
    // MessagesList
  },

  data () {
    return {
      navbar: {
        show: false
      }
    }
  },

  computed: {
    ...mapGetters('userModule', { authUser: 'getAuthUser' })
  },

  methods: {
    ...mapMutations('UIModule', ['toggleSidebar']),

    ...mapActions('authModule', ['logout']),

    ...mapActions('userModule', ['getAuthUser']),

    async closeSession () {
      const getval = await this.logout()

      EventBus.$emit('toast', 'Cierre de sesión', getval.message, {
        status: getval.error ? 'danger' : 'success'
      })

      if (getval.error) return

      this.$router.push({ name: 'Login' })
    }
  }
}
</script>
