import Resource from '@/api/Resource'

const partidas = new Resource('/api/partidas', 'partidaModule')

export default {
  namespaced: true,

  state: {
    /**
     * Representa a todas las partidas en el sistema
     */
    partidas: [],

    currentPartida: null,

    isCreatingResource: false,

    isGettingResource: false,

    isDeletingResource: false
  },

  getters: {
    getPartidas: state => {
      return state.partidas ? state.partidas : []
    },

    getAvailableList: state => {
      return !state.isCretingResource && !state.isGettingResource && !state.setIsDeletingResource
    },

    isEditingResource: state => Boolean(state.currentPartida),

    getCurrentEditing: state => state.currentPartida
  },

  mutations: {
    setPartidas (state, payload) {
      state.partidas = payload
    },

    setCurrentPartida (state, payload) {
      state.currentPartida = payload
    },

    setIsGettingResource (state, payload) {
      state.isGettingResource = payload
    },

    setIsCreatingResource (state, payload) {
      state.isCretingResource = payload
    },

    setIsDeletingResource (state, payload) {
      state.isDeletingResource = payload
    }
  },

  actions: {
    async getAll ({ commit }, periodo_fiscal_id) {
      const { error, data, message } = await partidas.all(
        { periodo_fiscal_id },
        'setIsGettingResource'
      )

      if (error) return { error, message }

      commit('setPartidas', data.data)

      return { data, message }
    },

    async create (_, payload) {
      let retval = {}

      try {
        const { data } = await partidas.create(payload)

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async getToEdit ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await partidas.show(id)

        retval = data

        commit('setCurrentPartida', { ...data.data })
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async update ({ commit }, { id, payload }) {
      let retval = {}

      try {
        const { data } = await partidas.update(id, payload)

        retval = data

        commit('setCurrentPartida', null)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async delete (_, id) {
      let retval = {}

      try {
        const { data } = await partidas.delete(id)

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    }
  }
}
