import service from '@/api/service'
import Resource from '@/api/Resource'
import _ from 'lodash'
// import service from '@/api/service'

const fuenteFinanciamiento = new Resource(
  '/api/fuentes-financiamiento',
  'fuenteFinanciamientoModule'
)

const panelControl = new Resource('/api/panel-de-control/', 'panelControl')

export default {
  namespaced: true,

  state: {
    list: [],

    //
    // Fuente de financiamiento actual, seleccionada desde el backend.
    currentFuenteFinanciamiento: null,

    currentResource: null,

    isCretingResource: false,

    isGettingResource: false,

    isDeletingResource: false
  },

  getters: {
    getList: state => state.list,

    isListAvailable: state => {
      return !state.isCretingResource && !state.isGettingResource && !state.setIsDeletingResource
    },

    isEditingResource: state => Boolean(state.currentResource),

    getCurrentEditing: state => state.currentResource,

    /**
     * @returns {Object} Obtiene la fuente de financiamiento seleccionada por defecto,
     *                   esta se consulta desde el backend.
     */
    getCurrentFuenteFinanciamiento: state => state.currentFuenteFinanciamiento
  },

  mutations: {
    setList (state, payload) {
      state.list = payload
    },

    setCurrentResource (state, payload) {
      state.currentResource = payload
    },

    setIsGettingResource (state, payload) {
      state.isGettingResource = payload
    },

    setIsCreatingResource (state, payload) {
      state.isCretingResource = payload
    },

    setIsDeletingResource (state, payload) {
      state.isDeletingResource = payload
    },

    setCurrentFuenteFinanciamiento (state, payload) {
      state.currentFuenteFinanciamiento = payload
    }
  },

  actions: {
    /**
     * Obtiene todas las fuentes de financiamiento.
     */
    async getAll ({ commit }, periodo_fiscal_id) {
      const { error, data, message } = await fuenteFinanciamiento.all(
        {
          periodo_fiscal_id
        },
        'setIsGettingResource'
      )

      if (error) return { error, message }

      commit('setList', data.data)

      return { data: data.data, message }
    },

    async getFuentesFinanciamientoList ({ commit }, periodo_fiscal_id) {
      let retval = {}

      try {
        const { data } = await service.get('api/fuentes-financiamiento?periodo_fiscal_id=' + periodo_fiscal_id)

        retval = data.data
      } catch (error) {
        console.log(error || '')

        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * Crea un recurso.
     *
     * @param {Object} payload.name           Nombre del recurso.
     * @param {Object} payload.description    Nombre del recurso.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async create ({ dispatch }, payload) {
      let retval = {}

      try {
        const { data } = await fuenteFinanciamiento.create(payload, 'setIsCreatingResource')
        retval = data
        dispatch('getAll')
      } catch (error) {
        retval = error.response.data
      }

      return retval
    },

    /**
     * Obtiene un recurso en específico.
     *
     * @param {int} id Id del recurso a obtener.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async getById ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await fuenteFinanciamiento.show(id)
        retval = data
        commit('setCurrentResource', data.data)
      } catch (error) {
        retval = error.response.data
      }

      return retval
    },

    async getToEdit ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await fuenteFinanciamiento.show(id)

        retval = data

        commit('setCurrentResource', { ...data.data })
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     *  Actualiza un recurso.
     *
     * @param {int}     id                    Id del recurso a actualizar.
     * @param {Object}  payload.name          Nombre del recurso a actualizar.
     * @param {Object}  payload.description   Descripción del recurso a actualizar.
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async updateById ({ commit, dispatch }, { id, payload }) {
      let retval = {}

      try {
        const { data } = await fuenteFinanciamiento.update(id, payload)
        retval = data
        commit('setCurrentResource', null)
        await dispatch('getAll')
      } catch (error) {
        retval = error.response.data
      }

      return retval
    },

    /**
     * Eliminar un recurso dado su id.
     *
     * @param {int} id Id del recurso a eliminar
     *
     * @return {boolean}  retval.error    Variable que indica si ha ocurrido un error.
     * @return {string}   retval.message  Variable que indica el resultado del proceso.
     * @return {Object}   retval.data     Variable que contiene o no, información adicional.
     */
    async deleteById (_, id) {
      let retval = {}

      try {
        const { data } = await fuenteFinanciamiento.delete(id, 'setIsDeletingResource')
        retval = data
      } catch (error) {
        retval = error.response.data
      }

      return retval
    },

    /**
     * getDefault.
     *
     * Obtiene la fuente de financiamiento por defecto.
     *
     * Esta fuente de financiamiento se configura desde un 'panel de control',
     * en donde existen varias opciones, entre ellas la fuente de financiamiento
     * por defecto para realizar consultas y acciones.
     *
     * Esta fuente de financiamiento se utiliza por ejemplo para obtener la proyección
     * de un periodo fiscal.
     *
     * @return {Number} id: 5
     * @return {String} modulo_front_end: "fuenteFinanciamientoModule/getAll"
     * @return {String} nombre: "Fuente de financiamiento por defecto"
     * @return {String} propiedad: "fuente_financiamiento_por_defecto"
     * @return {String} titulo: "Configurar fuentes de financiamiento"
     * @return {String} valor: "GLOBAL"
     */
    async getDefault ({ commit, dispatch }, { fuente, periodo_fiscal_id }) {
      let error, message, data
      ;({ error, data, message } = await panelControl.get(`/api/panel-de-control/${fuente}`))

      if (error) return { error, message }

      commit('setCurrentFuenteFinanciamiento', data.data)
      ;({ error, data, message } = await dispatch('getByName', {
        key: 'clave',
        value: data.data.valor,
        periodo_fiscal_id
      }))

      return { data, message }
    },

    async getByName ({ dispatch }, { key, value, periodo_fiscal_id }) {
      const { error, data, message } = await dispatch('getAll', periodo_fiscal_id)

      if (error) return { error, message }

      const retval = _.find(data, { [key]: value })

      return { data: retval, message }
    }
  }
}
