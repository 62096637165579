import moment from 'moment'
import feather from 'feather-icons'
import { EventBus } from '@/EventBus'
import numbro from 'numbro'
import service from '@/api/service'
import { mapActions } from 'vuex'

export default {
  mounted () {
    feather.replace()
    moment.locale('es')
  },

  computed: {
    $getPasswordValidationRules () {
      return {
        noRepeatingCharacters: this.$validateRepeatedCharacters,
        notConsecutiveNumbers: this.$validateConsecutiveNumbers,
        atLeastOneMayus: this.$validateAtLeastOneMayus,
        atLeastOneSymbol: this.$validateAtLeastOneSymbol,
        atLeastOneNumber: this.$validateAtLeastOneNumber
      }
    },

    $getPasswordValidationMessages () {
      return {
        required: 'La contraseña es requerida',
        min: 'La contraseña debe tener al menos 10 caractéres de longitud.',
        max: 'La contraseña no debe superar los 32 caractéres de longitud.',
        noRepeatingCharacters: 'La contraseña sólo puede tener 3 caractéres consecutivos iguales.',
        notConsecutiveNumbers:
          'La contraseña no debe contener números consecutivos de ningún tipo.',
        atLeastOneMayus: 'La contraseña debe contener al menos una letra mayúscula.',
        atLeastOneSymbol: 'La contraseña debe contener al menos un símbolo: (@#$%&^&=).',
        atLeastOneNumber: 'La contraseña debe contener al menos un número.'
      }
    }
  },

  methods: {
    ...mapActions('fiscalPeriodsModule', ['getActiveFiscalPeriod', 'getActiveFiscalPeriodByModule']),

    ...mapActions('fuenteFinanciamientoModule', ['getDefault']),

    $onGeneratePassword () {
      const characters = [
        {
          name: 'Lowercase',
          value: 'abcdefghijklmnopqrstuvwxyz',
          checked: true
        },
        {
          name: 'Uppercase',
          value: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
          checked: true
        },
        {
          name: 'Numbers',
          value: '0123456789',
          checked: true
        },
        {
          name: 'Special Characters',
          value: '+=&^%$#@',
          checked: true
        }
      ]

      let password = ''

      const gLength = 15

      let result = ''
      let charactersVal = ''

      for (var j = 0; j < characters.length; j++) {
        if (characters[j].checked) {
          charactersVal += characters[j].value
        }
      }

      for (var i = 0; i < gLength; i++) {
        result += charactersVal.charAt(Math.floor(Math.random() * charactersVal.length))
      }

      password = result

      return password
    },

    async $getPeriodoFiscalActivo () {
      const periodo = await this.getActiveFiscalPeriod()

      if (!periodo.id) {
        this.$notify({ error: true, message: 'Error al obtener el periodo fiscal activo' }, '')
        return null
      }

      return periodo
    },

    async $getPeriodoFiscalActivoPorModulo (modulo) {
      const periodo = await this.getActiveFiscalPeriodByModule(modulo)

      if (!periodo.id) {
        this.$notify({ error: true, message: 'Error al obtener el periodo fiscal activo' }, '')
        return null
      }

      return periodo
    },

    async $getFuentePorDefecto (fuente = 'fuente_financiamiento_por_defecto') {
      const periodoActivo = await this.$getPeriodoFiscalActivo()

      const { error, data, message } = await this.getDefault({
        fuente,
        periodo_fiscal_id: periodoActivo.id
      })

      if (error) return this.$notify({ error, message }, 'Obtener fuentes de financiamiento')

      return { data, message }
    },

    //
    // TODO: DELETE
    async $getActiveFiscalPeriod () {
      const { data } = await service.get('api/fiscal-periods/active')

      return data.data
    },

    $notify (getval = {}, title, attr = {}) {
      const obj = {}
      obj.icon = attr.icon || 'exclamation-triangle'
      obj.delay = attr.delay || 10000
      obj.title = title || 'Notificación'
      obj.toaster = attr.toaster || 'b-toaster-bottom-left'
      obj.closeable = !attr.closeable || true
      obj.closeOnTimeout = !attr.closeOnTimeout || true
      //
      obj.status = attr.status || getval.error ? 'danger' : 'success' || 'danger'
      obj.message = getval.message || 'Ha ocurrido un error, intente de nuevo más tarde'

      const payload = { ...obj, ...attr }

      const h = this.$createElement

      //
      // HEADER
      const vNodesHeader = h(
        'div',
        {
          class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mr-2']
        },
        [
          //
          // Icono
          h('b-icon', {
            class: 'mr-2',
            props: {
              icon: payload.icon
            }
          }),
          //
          // Título
          h('strong', { class: 'mr-2' }, 'Notificación'),
          //
          // Tiempo de espera del toast notification
          h(
            'small',
            {
              class: 'ml-auto text-italics text-dark'
            },
            this.$formatForHumans(new Date())
          )
        ]
      )

      //
      // BODY
      const vNodesMsg = h(
        'p',
        { class: ['text-center', 'mb-0'] },
        //
        // Mensaje
        [h('strong', payload.message)]
      )

      //
      // Instancia el notification toast
      this.$bvToast.toast([vNodesMsg], {
        bodyClass: 'bg-white text-black',
        title: [vNodesHeader],
        solid: true,
        variant: payload.status,
        noAutoHide: !payload.closeOnTimeout,
        noCloseButton: !payload.closeable,
        autoHideDelay: payload.delay,
        toaster: payload.toaster,
        appendToast: true
      })

      return true
    },

    $alert (getval, title, targetName, params) {
      EventBus.$emit(
        'alert',
        title,
        getval.message,
        {
          status: getval.error ? 'danger' : 'success',
          ...params
        },
        targetName
      )
    },

    $clearAlert () {
      EventBus.$emit('clear-alert')
    },

    $delay (ms) {
      return new Promise(resolve => setTimeout(_ => resolve(), ms))
    },

    $formatTimestamsFromFirebase (time) {
      const d = time.toDate()

      return this.$formatForHumans(d)
    },

    $formatDate (value) {
      return moment(value)
        .utc()
        .format('DD-MMMM-YYYY, h:mm a')
    },

    $customFormatDate (value, format) {
      return moment(value)
        .utc()
        .format(format)
    },

    $formatForHumans (value) {
      return moment(value, 'YYYY-MM-DD hh:mm:ss').fromNow()
    },

    $formatNumber (value) {
      if (!value) return `$${numbro(0).format({ thousandSeparated: true, mantissa: 2 })}`

      return `$${numbro(value).format({ thousandSeparated: true, mantissa: 2 })}`
    },

    $unformatNumber (value) {
      return Number.parseFloat(numbro.unformat(value))
    },

    $downAppWhenBannerIsActive (top = '0rem') {
      // Content
      const sideNavAndContent = document.querySelectorAll('#layoutSidenav #layoutSidenav_content')
      sideNavAndContent.forEach(el => {
        el.style.top = `calc(${top} + 3.625rem)`
        el.style.height = `calc(100vh - ${top})`
      })

      // Sidenav
      const sideNav = document.querySelectorAll('.nav-fixed #layoutSidenav #layoutSidenav_nav')
      sideNav.forEach(el => {
        el.style.top = `${top}`
        el.style.height = `calc(100vh - ${top})`
      })

      // Navbar
      const navBar = document.querySelectorAll('.nav-fixed .topnav')
      navBar.forEach(el => (el.style.top = top))
    },

    $can (permissions) {
      return this.$store.getters['userModule/getAuthPermissions'].some(p => p.includes(permissions))
    },

    $hasRoles (roles) {
      return this.$store.getters['userModule/getAuthRoles'].some(p => p.includes(roles))
    },

    /***************************************
     | VALIDACIONES DE CONTRASEÑA
     ***************************************/
    $validateRepeatedCharacters ({ value }) {
      const result = /([aA-zZ])\1{3}/.test(value)
      return !result
    },

    $validateConsecutiveNumbers ({ value }) {
      const result = /(?=123|987)\d{3}/.test(value)
      return !result
    },

    $validateAtLeastOneMayus ({ value }) {
      const result = /(.*[A-Z].*)/.test(value)
      return result
    },

    $validateAtLeastOneSymbol ({ value }) {
      const result = /(?=.*[@#$%^&+=]).*$/.test(value)
      return result
    },

    $validateAtLeastOneNumber ({ value }) {
      const result = /^(?=.{6,20}$)\D*\d/.test(value)
      return result
    },

    $onDownload (data, name) {
      const blob = new Blob([data], { type: 'blob' })
      const e = document.createEvent('MouseEvents')
      const a = document.createElement('a')

      //
      // Nombre del archivo
      a.download = name

      a.href = window.URL.createObjectURL(blob)
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':')
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
      a.dispatchEvent(e)
    },

    $isAuthorized (authorized, callback, from, permission) {
      //
      // Log, opcional
      if (from) console.log('from :>> ', from)
      if (permission) console.log('permission :>> ', permission)

      if (!authorized) return

      callback()
    },

    $getStatusVariant (status) {
      return status === 1 || status === true ? 'success' : 'danger'
    },

    $getStatusText (status) {
      return status === 1 || status === true ? 'Activo' : 'Inactivo'
    },

    $up () {
      window.scrollTo(0, 0)
      this.$emit('hide-form')
    }
  }
}
