import Dashboard from '@/views/DashboardView.vue'
import Login from '@/views/Auth/LoginView'

//
// Pantallas para la recuperación de contraseñas
const RecoverView = () => import('@/views/Auth/RecoverView')
const RecoverPasswordView = () => import('@/views/Auth/RecoverPasswordView')
const PasswordConfirmView = () => import('@/views/Auth/PasswordConfirmView')

//
// Pantallas del perfil
const ProfileView = () => import('@/views/Profile/ProfileView')
const Profile = () => import('@/components/Profile/Profile')

//
// Pantallas relacionadas a los usuarios, roles y permisos
const UsersRolesPermissionsView = () => import('@/views/Users/UsersRolesPermissionsView')

//
// Pantallas para mensajes y notificaciones
const NotificationsView = () => import('@/views/Notifications/NotificationsView')

//
// Pantallas para los periodos fiscales
const PeriodosFiscales = () => import('@/views/PeriodosFiscales/PeriodosFiscalesView')

//
// Claves presupuestales
const ClavesPresupuestales = () => import('@/views/ClavesPresupuestales/ClavesPresupuestalesView')

//
// Pólizas
// Recursos Materiales y Servicios
// TODO Refactorizar nombre para identificar que son las pólizas de Recursos Materiales
const MomentosContables = () => import('@/views/MomentosContables/MomentosContablesView.vue')
// Polizas recursos humanos
const PolizasRHImportView = () => import('@/views/PolizasRH/PolizasRHImportView')
const PolizasRHListView = () => import('@/views/PolizasRH/PolizasRHListView')
//
// Pantallas para unidades administrativas
const AdministrativeUnits = () => import('@/views/AdministrativeUnits/AdministrativeUnitsView')

//
// Pantalla para catálogos: Pretaciones
const Prestaciones = () => import('@/views/Catalogos/Prestaciones/PrestacionesView')

//
// Pantallas para las nóminas
const Nominas = () => import('@/views/Nominas/NominasView')

//
// Pantallas para la suficiencia presupuestal
const Suficiencia = () => import('@/views/SuficienciaPresupuestal/SuficienciaPresupuestalView')

//
// Pantalla para catalogos: Matriz de unidades administrativas
const MatrizUnidadesAdministrativas = () =>
  import('@/views/Catalogos/MatrizUnidadesAdministrativas/MatrizUnidadesAdministrativasView')

//
// Pantalla para bitacoras
const HistoryView = () => import('@/views/History/HistoryView')

// Pantallas para importación de layout Cap. 1000.
const PlaneacionView = () => import('@/views/Planeacion/PlaneacionView')

const PresupuestoFederalImport = () => import('@/views/PresupuestoFederal/PresupuestoFederalImportView')
const PresupuestoFederalList = () => import('@/views/PresupuestoFederal/PresupuestoFederalListView')
const AmpliacionReduccionImport = () => import('@/views/AmpliacionReduccion/AmpliacionReduccionImportView')
const AmpliacionReduccionList = () => import('@/views/AmpliacionReduccion/AmpliacionReduccionListView')

// Pantallas para concentrados de exportación de formatos a SAACG.NET
const EstatalFederalExport = () => import('@/views/Planeacion/Exportar/EstatalFederalExportView')
const AmpliacionesReduccionesExport = () => import('@/views/Planeacion/Exportar/AmpliacionesReduccionesExportView')
const TraspasosExport = () => import('@/views/Planeacion/Exportar/TraspasosExportView')

// Pantalla para catálogo de proyectos
const Proyectos = () => import('@/views/Catalogos/Proyectos/ProyectosView')

// Pantalla para catalogo de partidas
const Partidas = () => import('@/views/Catalogos/Partidas/PartidasView')

// Pantalla para catálogo de fuentes de financiamiento
const FuentesFinanciamiento = () =>
  import('@/views/Catalogos/FuentesFinanciamiento/FuentesFinanciamientoView')

// Pantalla para panel de control
const PanelDeControl = () => import('@/views/PanelDeControl/PanelDeControlView')

//
// Pantalla para mostrar reportes
const Reportes = () => import('@/views/Reportes/ReportesView')

//
// Pantalla para descargar las plantillas utilizadas por el sistema
const PlantillasPlaneacionPresupuestos = () => import('@/views/Plantillas/PlantillasPlaneacionPresupuestosView')
const PlantillasRecursosHumanos = () => import('@/views/Plantillas/PlantillasRecursosHumanosView')

//
// Pantalla para traspasos

const Traspasos = () => import('@/views/Traspasos/TraspasosView')
const VisualizarTraspasos = () => import('@/views/Traspasos/VisualizarTraspasosView')
const AutorizarTraspasos = () => import('@/views/Traspasos/AutorizarTraspasosView')

//
// Pantallas para saldos
const SaldosPorUnidad = () => import('@/views/Saldos/UnidadAdministrativaView')
const SaldosPorPartida = () => import('@/views/Saldos/PartidaPresupuestalView')
const SaldosPorMomento = () => import('@/views/Saldos/MomentoContableView')

/**
 * Pantalla de administración para usuarios, roles y permisos.
 *
 * Nota: Rutas expuestas para exportar.
 */
const usersAndPermissionsViews = [
  {
    path: '/users',
    name: 'Users',
    component: UsersRolesPermissionsView,
    props: { tabActive: 'users' },
    meta: {
      title: 'Usuarios',
      requiresAuth: true,
      requiresPermissions: ['acceso usuarios'],
      accordeon: 'usersAndPermissions'
    }
  },

  {
    path: '/roles',
    name: 'Roles',
    component: UsersRolesPermissionsView,
    props: { tabActive: 'roles' },
    meta: {
      title: 'Roles',
      requiresAuth: true,
      requiresPermissions: ['acceso roles'],
      accordeon: 'usersAndPermissions'
    }
  },

  {
    path: '/permisos',
    name: 'Permissions',
    component: UsersRolesPermissionsView,
    props: { tabActive: 'permissions' },
    meta: {
      title: 'Permisos',
      requiresAuth: true,
      requiresPermissions: ['acceso permisos'],
      accordeon: 'usersAndPermissions'
    }
  }
]

/**
 * Pantallas para la importación del capítulo mil y
 * otras pantallas relacionadas.
 */
const capituloMilViews = [
  {
    path: '/planeacion/importacion',
    name: 'RecursoEstatalImportacion',
    component: PlaneacionView,
    meta: {
      tab: 'importacion',
      title: 'Importación',
      requiresAuth: true,
      requiresPermissions: ['importar salarios'],
      accordeon: 'layouts'
    }
  },

  {
    path: '/planeacion/proyeccion',
    name: 'RecursoEstatalProyeccion',
    component: PlaneacionView,
    meta: {
      tab: 'proyeccion',
      title: 'Proyección',
      requiresAuth: true,
      requiresPermissions: ['generar proyeccion'],
      accordeon: 'layouts'
    }
  },

  {
    path: '/planeacion/analisis',
    name: 'RecursoEstatalAnalisis',
    component: PlaneacionView,
    meta: {
      tab: 'analisis',
      title: 'Análisis',
      requiresAuth: true,
      requiresPermissions: ['consultar analisis presupuesto'],
      accordeon: 'layouts'
    }
  }
]

const routes = [
  /**********************************************
   * GOOGLE AUTH
   **********************************************/
  {
    path: '/auth/:provider/callback',
    component: {
      template: '<div class="auth-component"></div>'
    }
  },

  /**********************************************
   * DASHBOARD
   **********************************************/
  {
    path: '/',
    component: Dashboard,
    meta: { requiresAuth: true }
  },

  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresAuth: true }
  },

  /**********************************************
   * LOGIN
   **********************************************/
  {
    path: '/login',
    name: 'Login',
    component: Login
  },

  {
    path: '/recuperar/',
    component: RecoverView,
    children: [
      {
        // Se solicita el email para el cambio de contraseña
        path: 'validacion',
        name: 'RecoverPassword',
        component: RecoverPasswordView
      },
      {
        // Se solicita la confirmación de la nueva contraseña
        path: '/recuperar/contrasena',
        name: 'PasswordConfirm',
        component: PasswordConfirmView,
        props: route => ({
          token: route.query.token
        })
      }
    ]
  },

  /**********************************************
   * USUARIO ACTUALMENTE AUTENTICADO
   **********************************************/
  {
    path: '/perfil',
    component: ProfileView,
    meta: { requiresAuth: true },
    props: route => ({ title: route.meta.title }),
    children: [
      {
        path: '/',
        name: 'Profile',
        component: Profile,
        meta: { title: 'Perfil' }
      }
    ]
  },

  /**********************************************
   * USUARIOS, ROLES Y PERMISOS
   **********************************************/
  ...usersAndPermissionsViews,

  /**********************************************
   * NOTIFICACIONES
   **********************************************/
  {
    path: '/notificaciones',
    name: 'Notifications',
    component: NotificationsView,
    meta: {
      requiresAuth: true,
      requiresPermissions: [],
      accordeon: 'notificaciones'
    }
  },

  /**********************************************
   * UNIDADES ADMINISTRATIVAS
   **********************************************/
  {
    path: '/unidades-administrativas',
    name: 'AdministrativeUnits',
    component: AdministrativeUnits,
    meta: {
      requiresAuth: true,
      requiresPermissions: ['acceso unidades administrativas'],
      accordeon: 'institucionales'
    }
  },

  /**********************************************
   * PERIODOS FISCALES
   **********************************************/
  {
    path: '/periodos-fiscales',
    name: 'PeriodosFiscales',
    component: PeriodosFiscales,
    meta: {
      requiresAuth: true,
      requiresPermissions: ['acceso periodos fiscales'],
      permissions: {
        create: 'crear periodos fiscales',
        update: 'editar periodos fiscales',
        delete: 'eliminar periodos fiscales',
        list: 'listar periodos fiscales'
      }
    }
  },

  /**********************************************
   * PRESTACIONES
   **********************************************/
  {
    path: '/catalogos/prestaciones',
    name: 'Prestaciones',
    component: Prestaciones,
    meta: {
      requiresAuth: true,
      requiresPermissions: ['acceso prestaciones'],
      accordeon: 'catalogos'
    }
  },

  /**********************************************
   * NÓMINAS
   **********************************************/
  {
    path: '/nominas',
    name: 'Nominas',
    component: Nominas,
    // FIX: FIX ACCESS
    // meta: { requiresAuth: true, requiresPermissions: ['acceso nominas'], accordeon: 'nominas' }
    meta: { requiresAuth: true, accordeon: 'nominas' }
  },

  /**********************************************
   * POLIZAS RH
   **********************************************/
  {
    path: '/polizas/rh/import',
    name: 'PolizasRHImport',
    component: PolizasRHImportView,
    meta: {
      requiresAuth: true,
      accordeon: 'nominas'
    }
  },
  {
    path: '/polizas/rh',
    name: 'PolizasRHList',
    component: PolizasRHListView,
    meta: {
      requiresAuth: true,
      accordeon: 'nominas'
    }
  },

  /**********************************************
   * SUFICIENCIA PRESUPUESTAL
   **********************************************/
  {
    path: '/suficiencia-presupuestal',
    name: 'Suficiencia',
    component: Suficiencia,
    meta: {
      requiresAuth: true,
      accordeon: 'presupuestos',
      requiresPermissions: ['consultar suficiencia presupuestal']
    }
  },

  /**********************************************
   * TRASPASOS
   **********************************************/
  {
    path: '/traspasos',
    name: 'Traspasos',
    component: Traspasos,
    meta: {
      requiresAuth: true,
      accordeon: 'traspasos',
      requiresPermissions: ['consultar traspasos']
    }
  },

  /**********************************************
   * VISUALIZAR TRASPASOS
   **********************************************/
  {
    path: '/traspasos-visualizar',
    name: 'VisualizarTraspasos',
    component: VisualizarTraspasos,
    meta: {
      requiresAuth: true,
      accordeon: 'traspasos'
    }
  },

  /**********************************************
   * AUTORIZAR TRASPASOS
   **********************************************/
  {
    path: '/traspasos-autorizar',
    name: 'AutorizarTraspasos',
    component: AutorizarTraspasos,
    meta: {
      requiresAuth: true,
      accordeon: 'traspasos'
    }
  },

  /**********************************************
   * FUENTES DE FINANCIAMIENTO
   **********************************************/
  {
    path: '/catalogos/fuentes-financiamiento',
    name: 'FuentesFinanciamiento',
    component: FuentesFinanciamiento,
    meta: {
      requiresAuth: true,
      requiresPermissions: ['acceso fuentes financiamiento'],
      accordeon: 'institucionales',
      permissions: {
        create: 'crear fuentes financiamiento',
        update: 'editar fuentes financiamiento',
        delete: 'eliminar fuentes financiamiento',
        list: 'listar fuentes financiamiento'
      }
    }
  },

  {
    path: '/catalogos/matriz-unidades-administrativas',
    name: 'MatrizUnidadesAdministrativas',
    component: MatrizUnidadesAdministrativas,
    meta: {
      requiresAuth: true,
      requiresPermissions: ['obtener matriz unidad administrativa prestacion'],
      accordeon: 'catalogos'
    }
  },

  {
    path: '/catalogos/proyectos',
    name: 'Proyectos',
    component: Proyectos,
    meta: {
      requiresAuth: true,
      requiresPermissions: ['acceso proyectos'],
      accordeon: 'institucionales',
      permissions: {
        create: 'crear proyectos',
        update: 'editar proyectos',
        delete: 'eliminar proyectos',
        list: 'listar proyectos'
      }
    }
  },

  {
    path: '/catalogos/partidas',
    name: 'Partidas',
    component: Partidas,
    meta: {
      requiresAuth: true,
      requiresPermissions: ['acceso partidas'],
      accordeon: 'institucionales',
      permissions: {
        create: 'crear partidas',
        update: 'editar partidas',
        delete: 'eliminar partidas',
        list: 'listar partidas'
      }
    }
  },

  /**********************************************
   * CLAVES PRESUPUESTALES
   **********************************************/
  {
    path: '/claves-presupuestales',
    name: 'ClavesPresupuestales',
    component: ClavesPresupuestales,
    meta: { requiresAuth: true, requiresPermissions: ['acceso claves presupuestales'] }
  },

  /**********************************************
   * MOMENTOS CONTABLES
   **********************************************/
  {
    path: '/momentos-contables/importar',
    name: 'MomentosContables',
    component: MomentosContables,
    meta: { requiresAuth: true, accordeon: 'recmatserv', requiresPermissions: ['importar poliza'] }
  },

  /**********************************************
   * BITACORAS
   **********************************************/
  {
    path: '/historial',
    name: 'Historial',
    component: HistoryView,
    meta: { requiresAuth: true }
  },

  /**********************************************
   * PANEL DE CONTROL
   **********************************************/
  {
    path: '/panel-de-control',
    name: 'PanelDeControl',
    component: PanelDeControl,
    meta: { requiresAuth: true }
  },

  /**********************************************
   * REPORTES
   **********************************************/
  {
    path: '/reportes',
    name: 'Reportes',
    component: Reportes,
    meta: { requiresAuth: true }
  },

  /**********************************************
   * SALDOS
   **********************************************/
  {
    path: '/saldos-unidad-administrativa',
    name: 'SaldosPorUnidad',
    component: SaldosPorUnidad,
    meta: {
      requiresAuth: true,
      accordeon: 'saldos'
    }
  },

  {
    path: '/saldos-partida-presupuestal',
    name: 'SaldosPorPartida',
    component: SaldosPorPartida,
    meta: {
      requiresAuth: true,
      accordeon: 'saldos'
    }
  },

  {
    path: '/saldos-por-momento-contable',
    name: 'SaldosPorMomento',
    component: SaldosPorMomento,
    meta: {
      requiresAuth: true,
      accordeon: 'saldos'
    }
  },

  /**********************************************
   * PLANTILLAS (Pantalla para descargar los layouts utilizados por el sistema)
   **********************************************/
  {
    path: '/plantillas-planeacion-presupuestos',
    name: 'PlantillasPlaneacionPresupuestos',
    component: PlantillasPlaneacionPresupuestos,
    meta: { requiresAuth: true }
  },
  {
    path: '/plantillas-recursos-humanos',
    name: 'PlantillasRecursosHumanos',
    component: PlantillasRecursosHumanos,
    meta: { requiresAuth: true }
  },

  /**********************************************
   * PRESUPUESTO FEDERAL Y AMPLIACIONES&REDUCCIONES
   **********************************************/
  {
    path: '/presupuesto-federal',
    name: 'PresupuestoFederalImport',
    component: PresupuestoFederalImport,
    props: { tabIndex: 0 },
    meta: {
      requiresAuth: true,
      requiresPermissions: ['importar presupuesto federal'],
      accordeon: 'layouts',
      permissions: {
        create: 'generar recurso federal',
        update: 'modificar recurso federal',
        delete: 'eliminar recurso federal',
        autorize: 'autorizar recurso federal'
      }
    }
  },

  {
    path: '/presupuesto-federal-view',
    name: 'PresupuestoFederalList',
    component: PresupuestoFederalList,
    props: { tabIndex: 0 },
    meta: {
      requiresAuth: true,
      requiresPermissions: ['consultar presupuesto federal'],
      accordeon: 'layouts',
      permissions: {
        create: 'generar recurso federal',
        update: 'modificar recurso federal',
        delete: 'eliminar recurso federal',
        autorize: 'autorizar recurso federal'
      }
    }
  },

  {
    path: '/ampliacion-reduccion-import',
    name: 'AmpliacionReduccionImport',
    component: AmpliacionReduccionImport,
    props: { tabIndex: 0 },
    meta: {
      requiresAuth: true,
      requiresPermissions: ['importar ampliacion y reduccion'],
      accordeon: 'layouts',
      permissions: {
        create: 'generar ampliacion y reduccion',
        update: 'modificar ampliacion y reduccion',
        delete: 'eliminar ampliacion y reduccion',
        autorize: 'autorizar ampliacion y reduccion'
      }
    }
  },

  {
    path: '/ampliacion-reduccion-view',
    name: 'AmpliacionReduccionList',
    component: AmpliacionReduccionList,
    props: { tabIndex: 0 },
    meta: {
      requiresAuth: true,
      requiresPermissions: ['consultar presupuesto federal'],
      accordeon: 'layouts',
      permissions: {
        create: 'generar recurso federal',
        update: 'modificar recurso federal',
        delete: 'eliminar recurso federal',
        autorize: 'autorizar recurso federal'
      }
    }
  },
  /**********************************************
   * Proyección capítulo 1000.
   **********************************************/
  {
    path: '/planeacion',
    component: PlaneacionView,
    meta: { requiresAuth: true },
    props: route => ({ title: route.meta.title, tab: route.meta.tab }),
    children: [...capituloMilViews]
  },
  /**********************************************
   * Exportación SAACG.NET
   **********************************************/
  {
    path: '/planeacion-exportar-estatal-federal',
    name: 'PlaneacionEstatalFederalExport',
    component: EstatalFederalExport,
    props: { tabIndex: 0 },
    meta: {
      requiresAuth: true,
      requiresPermissions: ['exportar estatal federal']
    }
  },
  {
    path: '/planeacion-exportar-ampliaciones-reducciones',
    name: 'PlaneacionAmpliacionesReduccionesExport',
    component: AmpliacionesReduccionesExport,
    props: { tabIndex: 0 },
    meta: {
      requiresAuth: true,
      requiresPermissions: ['exportar ampliaciones reducciones']
    }
  },
  {
    path: '/planeacion-exportar-traspasos',
    name: 'PlaneacionTraspasosExport',
    component: TraspasosExport,
    props: { tabIndex: 0 },
    meta: {
      requiresAuth: true,
      requiresPermissions: ['exportar formato traspasos']
    }
  },

  /**
   * Si la ruta no hace match con ninguna, entonces muestra un error 404.
   *
   * Nota: Siempre debe ser la última ruta.
   */
  {
    path: '*',
    name: 'NotFound',
    component: () => import('@/views/Errors/NotFound.vue')
  }
]

// console.log(JSON.stringify(routes, null, 4))

export { routes, usersAndPermissionsViews, capituloMilViews }
