<template>
  <div v-bind="$attrs">
    <check-authorization :requiresAuthorizations="['listar periodos fiscales']">
      <x-select
        v-model="periodo"
        dispatchPath="fiscalPeriodsModule/getFiscalPeriods"
        getterListPath="fiscalPeriodsModule/getAllFiscalPeriods"
        :formatListWith="['id', 'nombre']"
        no-validate
        no-clear-button
        prepend-text="Periodo fiscal"
      />
    </check-authorization>
  </div>
</template>

<script>
export default {
  name: 'XSelectPeriodoFiscal',

  async created () {
    const active = await (this.modulo ? this.$getPeriodoFiscalActivoPorModulo(this.modulo) : this.$getPeriodoFiscalActivo())
    this.periodo = active.id
  },

  props: {
    modulo: {
      type: String
    }
  },

  data () {
    return {
      periodo: null
    }
  },

  watch: {
    periodo: {
      immediate: true,
      handler (value) {
        if (!value) return

        this.onInput(value)
      }
    }
  },

  methods: {
    onInput (value) {
      this.$emit('input', value)
    }
  }
}
</script>
