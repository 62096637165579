import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedstate from 'vuex-persistedstate'
import SecureLs from 'secure-ls'
import { vuexfireMutations } from 'vuexfire'

import administrativeUnitModule from '@/store/modules/AdministrativeUnitModule'
import authModule from '@/store/modules/AuthModule'
import roleModule from '@/store/modules/RoleModule'
import permissionModule from '@/store/modules/PermissionModule'
import userModule from '@/store/modules/UserModule'
import UIModule from '@/store/modules/UIModule'
import fiscalPeriodsModule from '@/store/modules/FiscalPeriodsModule'
import historyModule from '@/store/modules/HistoryModule'
import fuenteFinanciamientoModule from '@/store/modules/FuenteFinanciamientoModule'
import fuenteFinanciamientoCapituloModule from '@/store/modules/FuenteFinanciamientoCapituloModule'
import planeacionModule from '@/store/modules/PlaneacionModule'
import prestacionesModule from '@/store/modules/PrestacionesModule'
import clavesPresupuestalesModule from '@/store/modules/ClavesPresupuestalesModule'
import proyectoModule from '@/store/modules/ProyectoModule'
import matrizUnidadesAdministrativasModule from '@/store/modules/MatrizUnidadesAdministrativasModule'
import partidaModule from '@/store/modules/PartidaModule'
import momentoModule from '@/store/modules/MomentoContableModule'
import presupuestoFederalModule from '@/store/modules/PresupuestoFederalModule'
import recursoFederalModule from '@/store/modules/RecursoFederalModule'
import ampliacionReduccionModule from '@/store/modules/AmpliacionReduccionModule'
import exportacionSAACGNETModule from '@/store/modules/ExportacionSAACGNETModule'
import momentosContablesModule from '@/store/modules/MomentosContablesModule'
import polizasRHModule from '@/store/modules/PolizasRHModule'
import polizasModule from '@/store/modules/PolizasModule'
import cuentaContableModule from '@/store/modules/CuentaContableModule'
import tipoGastoModule from '@/store/modules/TipoGastoModule'
import traspasoModule from '@/store/modules/TraspasoModule'
import saldoModule from '@/store/modules/SaldoModule'

const ls = new SecureLs({ isCompression: false })

Vue.use(Vuex)

const persistedstate = createPersistedstate({
  //
  // Pertiste en local storage los siguientes estados
  paths: [
    'userModule.authUser',
    'userModule.cloneAuthUser',
    'userModule.viewAsUser',
    //
    //
    'planeacionModule.proyeccion.processed',
    'planeacionModule.analisis.processed',
    //
    //
    'presupuestoFederalModule.selected',
    'presupuestoFederalModule.importacion.processed',
    'presupuestoFederalModule.edicion.processed',
    //
    //
    'ampliacionReduccionModule.selected',
    'ampliacionReduccionModule.fuente_financiamiento',
    'ampliacionReduccionModule.importacion.processed',
    'ampliacionReduccionModule.edicion.processed'
  ]
})

//
// El almacenamiento de información por razones de seguridad
// es encriptado, esto se hace automáticamente para los estados
// que se han establecido en el path perseverar.
if (process.env.NODE_ENV === 'production') {
  persistedstate.storage = {
    getItem: key => ls.get(key),
    setItem: (key, value) => ls.set(key, value),
    removeItem: key => ls.remove(key)
  }
}

export default new Vuex.Store({
  state: {},
  mutations: {
    ...vuexfireMutations
  },
  actions: {},
  modules: {
    prestacionesModule,
    UIModule,
    authModule,
    userModule,
    roleModule,
    permissionModule,
    administrativeUnitModule,
    fiscalPeriodsModule,
    historyModule,
    fuenteFinanciamientoModule,
    fuenteFinanciamientoCapituloModule,
    planeacionModule,
    clavesPresupuestalesModule,
    proyectoModule,
    matrizUnidadesAdministrativasModule,
    partidaModule,
    momentoModule,
    presupuestoFederalModule,
    recursoFederalModule,
    ampliacionReduccionModule,
    exportacionSAACGNETModule,
    momentosContablesModule,
    polizasRHModule,
    polizasModule,
    cuentaContableModule,
    tipoGastoModule,
    traspasoModule,
    saldoModule
  },
  plugins: [persistedstate]
})
