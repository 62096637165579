import service from '@/api/service'
import Resource from '@/api/Resource'

const administrativeUnits = new Resource('/api/administrative-units', 'administrativeUnitModule')

export default {
  namespaced: true,

  state: {
    /**
     * Representa a todas las unidades administrativas en el sistema
     */
    administrativeUnits: null,

    /**
     * Cuando se obtiene la informacion de una unidad administrativa a actualizar,
     * se almacena en esta variable, declarada como nula de forma inicial,
     * para saber si se está actualmente editando o no una unidad
     */
    editingAdministrativeUnit: null
  },

  getters: {
    hasAdministrativeUnits: state => Boolean(state.administrativeUnits),

    getAdministrativeUnits: (state, getters) =>
      getters.hasAdministrativeUnits ? state.administrativeUnits : [],

    isEditingAdministrativeUnit: state => Boolean(state.editingAdministrativeUnit),

    getAdministrativeUnitsForForms: (state, getters) => {
      if (getters.hasAdministrativeUnits) {
        const administrativeUnitsForForms = []

        getters.getAdministrativeUnits.forEach(administrativeUnit => {
          administrativeUnitsForForms.push({
            label: administrativeUnit.nombre,
            value: administrativeUnit.id
          })
        })

        return administrativeUnitsForForms
      }

      return []
    }
  },

  mutations: {
    resetState (state) {
      for (const key in state) {
        state[key] = null
      }
    },

    setAdministrativeUnits (state, administrativeUnits) {
      state.administrativeUnits = administrativeUnits
    },

    setAdministrativeUnitToEdit (state, administrativeUnit) {
      state.editingAdministrativeUnit = administrativeUnit
    }
  },

  actions: {
    async getAdministrativeUnits ({ commit }, periodo_fiscal_id) {
      const { error, data, message } = await administrativeUnits.all({ periodo_fiscal_id })

      if (error) return { error, message }

      commit('setAdministrativeUnits', data.data)

      return { data, message }
    },

    async getUnidadesAdministrativasList ({ commit }, periodo_fiscal_id) {
      let retval = {}

      try {
        const { data } = await service.get('api/administrative-units?periodo_fiscal_id=' + periodo_fiscal_id)

        retval = data.data
      } catch (error) {
        console.log(error || '')

        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async createAdministrativeUnit ({ dispatch }, payload) {
      let retval = {}

      try {
        const { data } = await administrativeUnits.create(payload)

        retval = data

        dispatch('getAdministrativeUnits')
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async getAdministrativeUnitToEdit ({ commit }, id) {
      let retval = {}

      try {
        const { data } = await administrativeUnits.show(id)

        retval = data

        commit('setAdministrativeUnitToEdit', { ...data.data })
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async updateAdministrativeUnit ({ commit, dispatch }, { id, payload }) {
      let retval = {}

      try {
        const { data } = await administrativeUnits.update(id, payload)

        retval = data

        commit('setAdministrativeUnitToEdit', null)

        dispatch('getAdministrativeUnits')
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async deleteAdministrativeUnit ({ dispatch }, id) {
      let retval = {}

      try {
        const { data } = await administrativeUnits.delete(id)

        retval = data

        dispatch('getAdministrativeUnits')
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    }
  }
}
