<template>
  <b-input-group :size="size">
    <template #prepend v-if="$slots.prepend || prependText !== '' || !noClearButton">
      <!-- Text -->
      <b-input-group-text v-if="prependText !== ''">{{ prependText }}</b-input-group-text>
      <!-- Clear button -->
      <b-button
        v-if="!noClearButton"
        :disabled="!getState && !noValidate"
        variant="light"
        @click="$emit('input', null)"
        style="z-index: 1"
      >
        Limpiar
      </b-button>
      <!-- Slot -->
      <slot name="prepend" v-else></slot>
    </template>

    <b-form-select
      :value="value"
      :options="options"
      :disabled="loading || disabled"
      :state="getState"
      @input="$emit('input', $event)"
      :class="classes"
    >
      <!-- Muestra un mensaje si está cargando información -->
      <template #first v-if="loading">
        <b-form-select-option :value="null">
          <loading></loading>
        </b-form-select-option>
      </template>

      <!-- Slot del usuario -->
      <template #first v-else>
        <b-form-select-option v-if="defaultText" :value="null" disabled>
          {{ defaultText }}
        </b-form-select-option>

        <slot v-else></slot>
      </template>
    </b-form-select>

    <template #append v-if="$slots.append || appendText !== ''">
      <b-input-group-text v-if="appendText !== ''">{{ appendText }}</b-input-group-text>
      <slot name="append" v-else></slot>
    </template>
  </b-input-group>
</template>

<script>
export default {
  name: 'XSelect',

  props: {
    value: {},

    /**
     * Cadena a la cual hará la petición para obtener la información relevante.
     */
    dispatchPath: {
      type: String,
      required: false,
      default: null
    },

    noload: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * Cadena la cual obtendrá los valores de la información en formato, clave y texto.
     */
    getterListPath: {
      type: String,
      required: true
    },

    defaultText: {
      type: String,
      required: false,
      default: null
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    },

    noValidate: {
      type: Boolean,
      required: false,
      default: false
    },

    prependText: {
      type: String,
      required: false,
      default: ''
    },

    noClearButton: {
      type: Boolean,
      required: false,
      default: false
    },

    noPrepend: {
      type: Boolean,
      required: false,
      default: false
    },

    size: {
      type: String,
      required: false,
      default: ''
    },

    appendText: {
      type: String,
      required: false,
      default: ''
    },

    classes: {
      type: String,
      default: ''
    },

    formatListWith: {
      type: Array,
      required: false,
      default: () => ['id', 'text']
    }
  },

  created () {
    this.load()
  },

  data () {
    return {
      loading: false
    }
  },

  computed: {
    options () {
      const list = this.$store.getters[this.getterListPath]

      return this.getListToXSelect(list, this.formatListWith[0], this.formatListWith[1])
    },

    getState () {
      if (this.noValidate) return null

      return Boolean(this.value)
    }
  },

  methods: {
    async load () {
      if (this.dispatchPath === null) return

      var getval
      // Definimos la variable noload para impedir que el control realice un llamado de forma inicial
      // al path que se le proporciona por medio de la propiedad dispathPath ya que no podemos asumir
      // que dicho llamado esté exento de errores (por ejemplo en el caso de las unidades administrativas
      // se requiere que primero se determine el período fiscal activo.
      if (!this.noload) {
        this.loading = true
        // Posteriormente se podría diseñar un mecanismo para proporcionar al path del módulo una forma
        // de obtener su payload
        getval = await this.$store.dispatch(this.dispatchPath)
        this.loading = false
      }

      return getval.error && this.$notify(getval, 'XSelect', { closeOnTimeout: false })
    },

    getListToXSelect (list, keyId, keyText) {
      if (!list) return []

      return list.map(f => ({ value: f[keyId], text: f[keyText] }))
    }
  }
}
</script>
